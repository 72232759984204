import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import FormLayout from '../layouts/form';
import ContactForm from '../components/contact-form/index';

const ContactPage = ({
  data: {
    datoCmsPage: {
      seoMetaTags,
      metaRobots,
      metaCanonical,
      header: headers,
    },
    datoCmsConfig: {
      pagePrivacy,
    },
  },
  pageContext: { lang: locale },
}) => (
  <FormLayout
    seoTags={seoMetaTags}
    metaRobots={metaRobots}
    metaCanonical={metaCanonical}
    headline={headers?.length > 0 ? headers[0].text ?? headers[0].headline : undefined}
    image={headers?.length > 0 ? headers[0].image : undefined}
  >
    <ContactForm locale={locale} privacyLink={pagePrivacy.fullUrlPath} />
  </FormLayout>
);

export default ContactPage;

ContactPage.defaultProps = {};

ContactPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
query contactQuery($url: String, $lang: String) {
    datoCmsPage(url: {eq: $url}, locale: {eq: $lang}) {
        ...SeoMetaTags
        ...header
        metaCanonical
        metaRobots
    }
    datoCmsConfig(locale: {eq: $lang}) {
        pagePrivacy {
            fullUrlPath
        }
    }
}`;

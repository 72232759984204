export default (callback) => (theme) => {
  const t = {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: '#F7F5EF',
      primary: '#AE9962',
      neutral60: '#AE9962',
      neutral50: '#AE9962',
      neutral40: '#AE9962',
      neutral30: '#AE9962',
      neutral20: '#AE9962',
      neutral10: '#E1CC9D',
      neutral0: '#F9F9F9',
      danger: '#AE9962',
      dangerLight: '#E1CC9D',
    },
  };

  return (callback || ((param) => param))(t);
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Helmet from '../../components/helmet';
import ContactHeader from '../../components/contact-header';

import * as styles from './component.module.scss';

const FormLayout = ({
  children: {
    main,
    aside,
    ...children
  },
  seoTags,
  metaRobots,
  metaCanonical,
  headline,
  image,
}) => (
  <>
    <Helmet tags={seoTags} metaRobots={metaRobots} metaCanonical={metaCanonical} />

    <div className={`page-wrapper-row ${styles.layout}`}>
      <aside className="col-16 col-md-6">
        <header>
          {aside && aside}
          {!aside && (
            <Link to="/">Juwelier Roller</Link>
          )}
        </header>
      </aside>

      <ContactHeader headline={headline} image={image}>
        {main && main}
        {!main && !aside && children}
      </ContactHeader>
    </div>
  </>
);

FormLayout.defaultProps = {
  metaRobots: undefined,
  metaCanonical: undefined,
  seoTags: undefined,
  image: undefined,
  headline: undefined,
  children: [],
};

FormLayout.propTypes = {
  metaRobots: PropTypes.string,
  metaCanonical: PropTypes.string,
  image: PropTypes.objectOf(PropTypes.any),
  headline: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.string,
  ]),
  seoTags: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default FormLayout;

import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../../lib/translate/index';

import CmsImageComponent from '../../content-elements/image';
import CmsTextComponent from '../../content-elements/text';

import * as ContactHeaderStyle from './component.module.scss';

const ContactHeader = (props) => {
  const {
    headline,
    image = undefined,
    children = [],
  } = props;
  return (
    <main className="page-content content-form col-16 col-md-10">
      <section className={ContactHeaderStyle.contentSection}>
        <div className="container-fluid">
          <Link to="/" className={`${ContactHeaderStyle.backButton} btn btn-outline`}>{t`form_contact_back_button_label`}</Link>

          {headline && (typeof headline === 'string' || headline instanceof String) && (
            <h2>{headline}</h2>
          )}

          {headline && !(typeof headline === 'string' || headline instanceof String) && (
            <CmsTextComponent content={headline} />
          )}

          {image && (
            <CmsImageComponent image={image} renderAs="div" className="contact-image-form" />
          )}

          {children && children}
        </div>
      </section>
    </main>
  );
};

ContactHeader.defaultProps = {
  children: undefined,
  image: undefined,
  headline: undefined,
};

ContactHeader.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  headline: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ContactHeader;

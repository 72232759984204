const submit = async (locale, formName, payload) => {
  const url = `${process.env.GATSBY_SERVICE_FORM_BASEURL}`
    + `/forms/${formName}`;

  return new Promise((resolve, reject) => {
    fetch(
      new Request(url, {
        headers: new Headers({
          'content-type': 'application/json',
        }),
        body: JSON.stringify({
          ...payload,
          locale,
        }),
        method: 'POST',
      }),
    )
      .then((res) => {
        // validation failed
        if (res.status === 422) {
          return res.json();
        }

        if (!res.ok || res.status > 201) {
          throw new Error('useFormSubmit error');
        }

        return null;
      })
      .then((errors) => {
        if (errors == null) {
          return null;
        }

        const errs = {};
        Object.keys(errors).forEach((name) => {
          let msgs = errors[name].filter(
            (msg) => msg.lang === locale,
          );
          if (msgs.length === 0) {
            msgs = errors[name];
          }
          errs[name] = msgs.map((msg) => msg.text);
        });

        return errs;
      })
      .then(
        (res) => resolve(res),
        (e) => reject(e),
      );
  });
};

// eslint-disable-next-line import/prefer-default-export
export { submit };

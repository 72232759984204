import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import themeOptions from '../react-select/theme';
import t from '../../lib/translate/index';
import { useFormSubmit } from '../../lib/service/formSubmit/useFormSubmit';

const ContactForm = ({ locale, privacyLink }) => {
  const privacyFieldLbl = t(
    'form_contact_privacy_label',
    `<a href="${privacyLink}" target="_blank" rel="noreferrer">${t('form_contact_privacy_link_label')}</a>`,
  );

  const genderOptions = t('form_contact_salutation_values')
    .split(',')
    .map((opt) => ({ value: opt, label: opt }));

  const formSubmit = useFormSubmit(locale, 'contact');

  const onSubmit = async ({ privacy, appointmentdate, ...data }, { setSubmitting }) => {
    setSubmitting(true);

    let date;
    if (appointmentdate?.length > 0) {
      const p = Date.parse(appointmentdate);
      if (p > 0) {
        const d = new Date(p);
        date = `${d.getUTCDate().toString().padStart(2, '0')}.${(d.getUTCMonth() + 1).toString().padStart(2, '0')}.${d.getUTCFullYear()}`;
      } else {
        // fallback: appointmentdate can not be parsed
        date = appointmentdate;
      }
    }

    const validationErrors = await formSubmit.mutateAsync({
      ...data,
      privacy: privacy === true ? 1 : -1,
      appointmentdate: date,
    });

    setSubmitting(false);

    if (!validationErrors) {
      // no validation errors, we're done here
      return;
    }

    // validation errors

    const validationMsg = Object.keys(validationErrors)
      .reduce((carry, key) => {
        carry.push(`${key}: ${validationErrors[key].join(' | ')}`);
        return carry;
      }, []).join('\n');

    // eslint-disable-next-line no-alert
    alert(validationMsg);
  };

  return (
    <>
      {formSubmit.isSuccess && (
        <>
          <p>{t`form_contact_submit_success_txt`}</p>
          <footer className="form-footer">
            <div className="inner">
              <button type="submit" disabled className="btn btn-primary">{t`form_contact_submit_button_label`}</button>
              <button type="button" className="btn btn-outline" onClick={() => navigate(-1)}>
                {t`form_contact_back_button_label`}
              </button>
            </div>
          </footer>
        </>
      )}

      {formSubmit.isLoading && (
        <>
          <span>LOADING...</span>
          <footer className="form-footer">
            <div className="inner">
              <button type="submit" disabled className="btn btn-primary">{t`form_contact_submit_button_label`}</button>
              <button type="button" disabled className="btn btn-outline">{t`form_contact_back_button_label`}</button>
            </div>
          </footer>
        </>
      )}

      {!formSubmit.isLoading && !formSubmit.isSuccess && (
        <Formik
          initialValues={{
            gender: '',
            title: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            itemnumber: '',
            manufacturerreferencenumber: '',
            appointmentdate: '',
            appointmenttime: '',
            message: '',
            privacy: false,
          }}
          validationSchema={Yup.object().shape({
            gender: Yup.string()
              .oneOf([genderOptions.map(({ value: v }) => v)], t`validation_err_required`)
              .required(t`validation_err_required`),
            title: Yup.string(),
            firstname: Yup.string().required(t`validation_err_required`),
            lastname: Yup.string().required(t`validation_err_required`),
            email: Yup.string().email(t`validation_err_email`).required(t`validation_err_required`),
            phone: Yup.string(),
            itemnumber: Yup.string(),
            manufacturerreferencenumber: Yup.string(),
            appointmentdate: Yup.string(),
            appointmenttime: Yup.string(),
            message: Yup.string().required(t`validation_err_required`),
            privacy: Yup.boolean().oneOf([true], t`validation_err_required`).required(t`validation_err_required`),
          })}
          onSubmit={onSubmit}
        >
          {({
            values,
            setFieldValue,
          }) => (
            <Form className="needs-validation">
              <p className="required-info">{t`form_required_fields`}</p>
              <div className="form-group">
                <label htmlFor="salutation">
                  {t`form_contact_salutation_label`}
                  <span className="required_asterisk">{t`form_required_asterisk`}</span>
                </label>
                <Select
                  name="gender"
                  value={(genderOptions.find((opt) => opt.value === values.gender))}
                  onChange={(opt) => { setFieldValue('gender', opt.value); }}
                  placeholder={t`form_contact_salutation_placeholder`}
                  options={genderOptions}
                  theme={themeOptions()}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
                <ErrorMessage name="gender">
                  { (msg) => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className="form-group">
                <label htmlFor="title">{t`form_contact_title_label`}</label>
                <Field className="form-control" type="text" name="title" id="title" placeholder={t`form_contact_title_placeholder`} />
              </div>
              <div className="form-group">
                <label htmlFor="firstname">
                  {t`form_contact_surname_label`}
                  <span className="required_asterisk">{t`form_required_asterisk`}</span>
                </label>
                <Field className="form-control" type="text" name="firstname" id="firstname" placeholder={t`form_contact_surname_placeholder`} />
                <ErrorMessage name="firstname">
                  { (msg) => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className="form-group">
                <label htmlFor="lastname">
                  {t`form_contact_lastname_label`}
                  <span className="required_asterisk">{t`form_required_asterisk`}</span>
                </label>
                <Field className="form-control" type="text" name="lastname" id="lastname" placeholder={t`form_contact_lastname_placeholder`} />
                <ErrorMessage name="lastname" className="invalid-feedback">
                  { (msg) => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  {t`form_contact_email_label`}
                  <span className="required_asterisk">{t`form_required_asterisk`}</span>
                </label>
                <Field className="form-control" type="email" name="email" id="email" placeholder={t`form_contact_email_placeholder`} />
                <ErrorMessage name="email">
                  { (msg) => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className="form-group">
                <label htmlFor="phone">{t`form_contact_phone_label`}</label>
                <Field className="form-control" type="phone" name="phone" id="phone" placeholder={t`form_contact_phone_placeholder`} />
              </div>
              <div className="form-group">
                <label htmlFor="itemnumber">{t`form_contact_sku_label`}</label>
                <Field className="form-control" type="text" name="itemnumber" id="itemnumber" placeholder={t`form_contact_sku_placeholder`} />
              </div>
              <div className="form-group">
                <label htmlFor="manufacturerreferencenumber">{t`form_contact_manufacturer_reference_number_label`}</label>
                <Field className="form-control" type="text" name="manufacturerreferencenumber" id="manufacturerreferencenumber" placeholder={t`form_contact_manufacturer_reference_number_placeholder`} />
              </div>
              <div className="form-group">
                <label htmlFor="appointmentdate">{t`form_contact_date_label`}</label>
                <Field className="form-control" type="date" name="appointmentdate" id="appointmentDate" min={(new Date()).toISOString().replace(/T(.*)/, '')} />
              </div>
              <div className="form-group">
                <label htmlFor="appointmenttime">{t`form_contact_time_label`}</label>
                <Field className="form-control" type="text" name="appointmenttime" id="appointmenttime" placeholder={t`form_contact_time_placeholder`} />
              </div>
              <div className="form-group">
                <label htmlFor="message">
                  {t`form_contact_message_label`}
                  <span className="required_asterisk">{t`form_required_asterisk`}</span>
                </label>
                <Field className="form-control" name="message" as="textarea" rows="3" id="message" placeholder={t`form_contact_message_placeholder`} />
                <ErrorMessage name="message">
                  { (msg) => <p>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className="form-group">
                <div className="form-check">
                  <Field className="form-check-input" name="privacy" type="checkbox" id="privacy" />
                  {/* eslint-disable-next-line max-len */}
                  {/* eslint-disable-next-line react/no-danger,jsx-a11y/label-has-associated-control */}
                  <label className="form-check-label" htmlFor="privacy" dangerouslySetInnerHTML={{ __html: privacyFieldLbl }} />
                  <ErrorMessage name="privacy" id="privacy">
                    { (msg) => <p>{msg}</p>}
                  </ErrorMessage>
                </div>
              </div>
              <footer className="form-footer">
                <div className="inner">
                  <button type="submit" className="btn btn-primary">{t`form_contact_submit_button_label`}</button>
                  <button type="button" className="btn btn-outline" onClick={() => navigate(-1)}>
                    {t`form_contact_back_button_label`}
                  </button>
                </div>
              </footer>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
ContactForm.propTypes = {
  privacyLink: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};
export default ContactForm;
